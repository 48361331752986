<div class="d-flex flex-column justify-content-center align-items-center row">
  <div class="col-md-8">
    <div class="list">
      <div class="news-box my-3">
        <div class="news-text">
          <h2>Sefirin Kızı</h2>
          <h4>Yeni dizi Sefirin Kızı'nın afişi çıktı.
          </h4>
          <div class="news-image">
            <img
              src="https://cdn1.ntv.com.tr/gorsel/IaL9Q_lQukGaEPNEAQWIaA.jpg?width=952&height=540&mode=both&scale=both"
              alt=""
            />
          </div>
          <p>
            Star’ın iddialı projelerinden Sefirin Kızı’nın afişi yayınlandı. Engin Akyürek ve Neslihan Atagül Doğulu’nun ilk kez partner olarak izleyicinin karşısına çıkacağı dizinin afişi büyük beğeni topladı.

          </p>
          <p>
            Engin Akyürek ve Neslihan Atagül Doğulu’nun ilk kez partner olarak izleyicinin karşısına çıkacağı Star’ın iddialı projelerinden Sefirin Kızı’nın afişi yayınlandı.
          </p>
          <p>
            Sefirin Kızı Nare (Neslihan Atagül Doğulu) ile yörenin sözü geçen varlıklı iş insanı Sancar’ın (Engin Akyürek) destansı aşk hikayelerinin anlatıldığı dizinin başrol oyuncuları afişteki uyumları ile dikkat çekti. İlk günden itibaren setten paylaşılan fotoğraflar ve yayınlanan tanıtımları ile çok konuşulan dizinin afişi de sosyal medyada büyük ilgi gördü.
          </p>
          <p>
            Yapımına O3 Medya ve NGM’nin birlikte imza attığı Sefirin Kızı, güçlü oyuncu kadrosu ve duygu yüklü anlatımı ile 16 Aralık Pazartesi 20.00’de  Star’da başlayacak.
          </p>
          <a
            href="https://www.ntv.com.tr/yasam/yeni-dizi-sefirin-kizininafisi-cikti,GM40HFDhiUS3x9mMoq1Iuw"
            target="_blank"
            >Kaynak</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
