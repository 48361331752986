<div class="footer w-100">
  <div
    class="content m-3 w-100"
  >
    <p>
      Copyright © 2022 Sem's Casting | Sitede bulunan yazılı ve görsel
      materyaller izinsiz kullanılamaz.
    </p>
    <div class="social">
      <a href="https://www.instagram.com/semscasting/" target="_blank">
        <img src="../../../assets/social/instagram.svg" alt="" />
      </a>
      <a href="https://twitter.com/semscasting" target="_blank">
        <img src="../../../assets/social/twitter.svg" alt="" />
      </a>
      <!-- <a href="">
        <img src="../../../assets/social/imdb.png" alt="" />
      </a>
      <a href="">
        <img src="../../../assets/social/mail.png" alt="" />
      </a> -->
    </div>

  </div>
</div>
