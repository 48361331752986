import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss'],
})
export class ResetComponent implements OnInit {

  user = {
    email: '',
  };

  constructor(private router: Router) {}

  ngOnInit(): void {}

  reset() {
    const auth = getAuth();
    sendPasswordResetEmail(auth, this.user.email)
      .then(() => {
        // Password reset email sent!
        // ..
        console.log('success')
        this.router.navigateByUrl('auth/signin');

      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
      });
  }
}
