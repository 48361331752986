<div class="container">
  <div class="head">
    <h1 class="title">Sem's Casting</h1>
    <div class="user">
      <p>Kullanıcı: {{ user.email }}</p>
      <button class="logout" (click)="signOut()">Çıkış yap</button>
    </div>
  </div>
  <hr />
  <div class="buttons">
    <button class="join" [ngClass]="{active: activeButton == 'JOIN'}" (click)="join()">Başvuruları Gör</button>
    <button class="contact" [ngClass]="{active: activeButton == 'CONTACT'}" (click)="contact()">
      İletişim İsteklerini Gör
    </button>
  </div>
  <div class="join my-3" *ngIf="activeButton == 'JOIN'">
    <div
      class="accordio my-1"
      id="accordionExample"
      *ngFor="let item of joinList; let i = index"
    >
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingOne">
          <button
            class="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            [attr.data-bs-target]="'#test' + i"
          >
            {{ item.isim }}
          </button>
          <button class="btn btn-delete mx-3" (click)="onDeleteJoin(item)">
            Sil
          </button>
        </h2>
        <div
          id="test{{ i }}"
          class="accordion-collapse collapse"
          aria-labelledby="headingOne"
          data-bs-parent="#accordionExample"
        >
          <div class="accordion-body">
            <h3>Genel Bilgiler</h3>
            <ul>
              <li>İsim Soyisim: {{ item?.isim }}</li>
              <li>Cinsiyet: {{ item.cinsiyet }}</li>
              <li>Doğum Tarihi: {{ item.dogumgunu }}</li>
              <li>Doğum Yeri: {{ item.dogumyeri }}</li>
              <li>Boy: {{ item.boy }}</li>
              <li>Kilo: {{ item.kilo }}</li>
              <li>Saç Rengi: {{ item.sacrengi }}</li>
              <li>Göz Rengi: {{ item.gozrengi }}</li>
              <li>Eğitim: {{ item.egitim }}</li>
              <li>Meslek: {{ item.meslek }}</li>
              <li>Tiyatro Eğitimi: {{ item.tiyatro }}</li>
              <li>Özel Yetenek: {{ item.ozelyetenek }}</li>
            </ul>
            <h3>İletişim Bilgileri</h3>
            <ul>
              <li>Şehir: {{ item.sehir }}</li>
              <li>İlçe: {{ item.ilce }}</li>
              <li>Telefon: {{ item.telefon }}</li>
              <li>Mail: {{ item.mail }}</li>
            </ul>
            <h3>Fotoğraf ve Video</h3>
            <a [href]="item.media1"   target="_self" download>
              Download
            </a>
            <a href="item.media1"  download>
              Download 2
            </a>
            <a href="/files/adlafjlxjewfasd89asd8f.pdf" download="expenses.pdf">Download Your Expense Report</a>

            <button class="btn btn-download" (click)="download(item)">
              Download
            </button>

            <!-- <img [src]="item.media1" class="image" alt="" />
            <img [src]="item.media2" class="image" alt="" />
            <img [src]="item.media3" class="image" alt="" />
            <video controls [src]="item.media4" class="video"></video> -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="contact my-3" *ngIf="activeButton == 'CONTACT'">
    <div
      class="accordio my-1"
      id="accordionExample"
      *ngFor="let item of contactList; let i = index"
    >
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingOne">
          <button
            class="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#i"
            aria-expanded="true"
            aria-controls="i"
          >
            {{ item.isim }}
          </button>
          <button class="btn btn-delete mx-3" (click)="onDeleteContact(item)">
            Sil
          </button>
        </h2>
        <div
          id="i"
          class="accordion-collapse collapse"
          aria-labelledby="headingOne"
          data-bs-parent="#accordionExample"
        >
          <div class="accordion-body">
            <h3>Genel Bilgiler</h3>
            <ul>
              <li>İsim Soyisim: {{ item.isim }}</li>
              <li>Telefon: {{ item.telefon }}</li>
              <li>Mail: {{ item.mail }}</li>
              <li>Konu: {{ item.konu }}</li>
              <li>Mesaj: {{ item.mesaj }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
