export interface IJoin {
  _id?: string;
  isim?: string;
  cinsiyet?: string;
  dogumgunu?: Date;
  dogumyeri?: string;
  boy?: string;
  kilo?: string;
  sacrengi?: string;
  gozrengi?: string;
  egitim?:string;
  meslek?: string;
  tiyatroegitimi?: string;
  ozelyetenek?: string;
  sehir?: string;
  ilce?: string;
  telefon?: string;
  email?: string;
  media1?: any;
  media2?: any;
  media3?: any;
  media4?: any;
  tarih?: Date;
}

export class Join implements IJoin {
  _id?: string = '';
  isim?: string = '';
  cinsiyet?: string = '';
  dogumgunu?: Date;
  dogumyeri?: string = '';
  boy?: string = '';
  kilo?: string = '';
  sacrengi?: string = '';
  gozrengi?: string = '';
  egitim?:string = '';
  meslek?: string = '';
  tiyatroegitimi?: string = '';
  ozelyetenek?: string = '';
  sehir?: string = '';
  ilce?: string = '';
  telefon?: string = '';
  email?: string = '';
  media1?: any = '';
  media2?: any = '';
  media3?: any = '';
  media4?: any = '';
  tarih?: Date;
}