<div class="d-flex flex-column justify-content-center align-items-center row">
  <div class="col-md-8">
    <div class="list">
      <div class="news-box my-3">
        <div class="news-text">
          <h2>Dünyayla Benim Aramda</h2>
          <h4>
            Yönetmenliğini Hülya Gezer’in üstlendiği, senaryosunu Pınar Bulut’un yazdığı Disney Plus'ın yeni yerli dizisi 'Dünyayla Benim Aramda'nın yayın tarihi 14 Eylül olarak belirlendi.  Yapımcılığını MF Yapım’ın üstlendiği dizinin oyuncu kadrosunda, Demet Özdemir, Buğra Gülsoy, Hafsanur Sancaktutan, Metin Akdülger, İbrahim Selim, Melisa Döngel, Ali Yoğurtçuoğlu ve Zerrin Tekindor gibi isimler yer alıyor.
          </h4>
          <div class="news-image">
            <img
              src="../../../../assets/casting/2022/dba.jpeg"
              alt=""
            />
          </div>
          <p>
            ‘Dünyayla Benim Aramda’, büyük bir aşk ve tutkuyla başlayan ilişkisinin artık eskisi gibi olmadığını fark eden bir kadının ilişkisini kurtarmak adına sosyal medya üzerinden oynadığı oyunu ve beraberinde yaşananları bağlılık kavramı üzerinden ele alıyor.
          </p>
          <a
            href="https://www.disneyplus.com/tr-tr?&cid=DSS-Search-Google-71700000104174028-&s_kwcid=AL!8468!3!642295352054!p!!g!!d%C3%BCnyayla%20benim%20aramda%20disney%20plus&gclid=Cj0KCQiAofieBhDXARIsAHTTldq-Ag-_Ov12N_Dz8top7dH68oWSEqRLApkN_ymzYDRtn20AARW0c-gaAjLVEALw_wcB&gclsrc=aw.ds"
            target="_blank"
            >Kaynak</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
