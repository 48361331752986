import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'sems-management';
  isAuth: boolean = false;
  constructor(public router: Router, public activeRoute: ActivatedRoute) {
    router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((s:any) => {
        
        if (s.url === '/auth') {
          this.isAuth = true;
        } else if (s.url === '/auth/signin') {
          this.isAuth = true;
        } else if (s.url === '/auth/signup') {
          this.isAuth = true;
        } else if (s.url === '/auth/profile') {
          this.isAuth = true;
        } else if (s.url === '/auth/reset') {
          this.isAuth = true;
        }
        
      });
  }
  ngOnInit() {
  }
}
