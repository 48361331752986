<div class="d-flex flex-column justify-content-center align-items-center row">
  <div class="col-md-8">
    <div class="list">
      <div class="news-box my-3">
        <div class="news-text">
          <h2>Kaderimin Oyunu</h2>
          <h4>‘Kaderimin Oyunu’nun Afişi Yayınlandı'</h4>

          <p>
            Star’ın yeni dizisi ‘Kaderimin Oyunu’’nun merakla beklenen afişi
            yayınlandı. Nefes kesen hikayesi ve güçlü oyuncu kadrosuyla yakında
            ekrana gelecek olan dizinin afişi büyük merak uyandırdı.
          </p>

          <p>
            İzleyici ile buluşmak için gün sayan Star’ın yeni dizisi ‘Kaderimin
            Oyunu’nun afişi yayınlandı. Çekimleri Amasya’da başlayan ve
            İstanbul’da devam edecek olan “Kaderimin Oyunu”, afişiyle de büyük
            merak uyandırdı. Başrollerini; Öykü Karayel, Akın Akınözü, Meriç
            Aral ve Sarp Apak’ın paylaştığı dizi, yakında başlıyor. Yapımını NGM
            Medyanın üstlendiği ‘Kaderimin Oyunu’nun yönetmen koltuğunda Emre
            Kabakuşak oturuyor. Dizinin senaryosunu ise Gül Abus Semerci kaleme
            alıyor. ‘Kaderimin Oyunu’ nefes kesen hikayesi ile yakında Star’da.
          </p>
          <a
            href="https://www.startv.com.tr/dizi/kaderimin-oyunu/haber/kaderimin-oyununun-afisi-yayinlandi"
            target="_blank"
            >Kaynak</a
          >
        </div>
        <div class="news-image">
          <img
            src="../../../../assets/casting/2021/ko.webp"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</div>
