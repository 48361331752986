<div class="d-flex flex-column justify-content-center align-items-center row">
  <div class="col-md-8">
    <div class="list" *ngFor="let item of newsList">
      <div class="news-box my-3" >
        <div class="news-text">
          <h4>{{item.title}}</h4>
          <h5>
            {{item.subtitle}}
          </h5>
          <p>
            {{item.text}}
          </p>
          <button class="btn black-button"[routerLink]="item.route">Haberi Oku</button>
        </div>
        <div class="news-image">
          <img [src]="item.image" alt="" />
        </div>
      </div>
      <hr>
    </div>
  </div>
</div>
