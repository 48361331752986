<div class="d-flex flex-column justify-content-center align-items-center row">
  <div class="col-md-8 col-sm-12">
    <!-- <h3>CASTING</h3> -->
    <div class="my-3" *ngFor="let item of lists">
      <h4>{{ item.year }}</h4>
      <div class="card-list">
        <div
          class="card"
          *ngFor="let card of item.movies"
          style="background-image: url('{{ card.src }}')"
        >
          <div class="wrap">
            <h2>{{ card.title }}</h2>
            <div class="icons">
              <a href="{{ card.imdbLink }}" target="_blank">
                <i class="fab fa-imdb" aria-hidden="true"></i>
                {{ card.imdb }}
              </a>
              <!-- <a href="{{ card.tw }}" target="_blank">
                <i class="fab fa-twitter" aria-hidden="true"></i>
              </a>
              <a href="{{ card.tik }}" target="_blank">
                <i class="fab fa-tiktok" aria-hidden="true"></i>
              </a> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

