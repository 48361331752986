<div class="d-flex flex-row justify-content-center w-100">
  <div class="col-md-8 col-sm-12">
    <div class="logo" *ngIf="pageWidth > 1310; px">
      <a routerLink="/">
        <h1 class="title">Sem's Casting</h1>
        <h5>Sema Hatun Bilmiş</h5>
      </a>
    </div>
    <div class="logo" *ngIf="pageWidth <= 1310; px">
      <div class="name">
        <a routerLink="/">
          <h1 class="title">Sem's Casting</h1>
          <h5>Sema Hatun Bilmiş</h5>
        </a>
      </div>
      <div>
        <a (click)="openNav()" *ngIf="!isNavOpen">
          <img src="../../../assets/icons/menu.png" class="menu" alt="" />
        </a>
        <a (click)="closeNav()" *ngIf="isNavOpen">
          <img src="../../../assets/icons/close.png" class="menu" alt="" />
        </a>
      </div>
    </div>
    <div class="my-3 nav-row" *ngIf="pageWidth > 1310; px">
      <div class="nav-item">
        <a
          class="nav-link"
          routerLink="/"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          >Anasayfa</a
        >
      </div>
      <div class="nav-item">
        <a
          class="nav-link"
          routerLink="about"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          >Hakkımızda</a
        >
      </div>
      <div class="nav-item">
        <a
          class="nav-link"
          routerLink="casting"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          >Casting</a
        >
      </div>
      <div class="nav-item">
        <a
          class="nav-link"
          routerLink="management"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          >Management</a
        >
      </div>
      <div class="nav-item">
        <a
          class="nav-link"
          routerLink="news"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          >Haber</a
        >
      </div>
      <div class="nav-item">
        <a
          class="nav-link"
          routerLink="join"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          >Başvuru</a
        >
      </div>
      <div class="nav-item">
        <a
          class="nav-link"
          routerLink="contact"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          >İLETİŞİM</a
        >
      </div>
    </div>
    <div class="mobile-row" *ngIf="isNavOpen" [@inOutAnimation]>
      <div class="nav-item">
        <a
          class="nav-link"
          routerLink="/"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          (click)="closeNav()"
          >Anasayfa</a
        >
      </div>
      <div class="nav-item">
        <a
          class="nav-link"
          routerLink="about"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          (click)="closeNav()"
          >Hakkımızda</a
        >
      </div>
      <div class="nav-item">
        <a
          class="nav-link"
          routerLink="casting"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          (click)="closeNav()"
          >Casting</a
        >
      </div>
      <div class="nav-item">
        <a
          class="nav-link"
          routerLink="management"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          (click)="closeNav()"
          >Management</a
        >
      </div>
      <div class="nav-item">
        <a
          class="nav-link"
          routerLink="news"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          (click)="closeNav()"
          >Haber</a
        >
      </div>
      <div class="nav-item">
        <a
          class="nav-link"
          routerLink="join"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          (click)="closeNav()"
          >Başvuru</a
        >
      </div>
      <div class="nav-item">
        <a
          class="nav-link"
          routerLink="contact"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          (click)="closeNav()"
          >İLETİŞİM</a
        >
      </div>
    </div>
  </div>
</div>
