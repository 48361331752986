import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import {
  getFirestore,
  collection,
  getDocs,
  doc,
  deleteDoc,
} from 'firebase/firestore';
import { getStorage, ref, getBlob, getDownloadURL } from 'firebase/storage';

const db = getFirestore();
const storage = getStorage();

enum Buttons {
  Join = 'JOIN',
  Contact = 'CONTACT',
}
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  auth = getAuth();
  user: any = {};
  joins: any;
  joinList: any[] = [];
  contacts: any;
  contactList: any[] = [];

  activeButton?: Buttons;
  constructor(private router: Router, private cdr: ChangeDetectorRef) {
    onAuthStateChanged(this.auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        this.user = user;
        // console.log(this.user);
      } else {
        // User is signed out
        // ...
      }
    });
  }

  ngOnInit(): void {
    this.getJoins();
    this.getContacts();
    this.activeButton = Buttons.Join;
  }

  async getJoins() {
    const querySnapshot = await getDocs(collection(db, 'joins'));
    querySnapshot.forEach((doc) => {
      this.joins = doc.data();
      this.joinList.push(this.joins);
      this.cdr.detectChanges();
      // console.log(this.joinList);
    });
  }

  async getContacts() {
    const querySnapshot = await getDocs(collection(db, 'contacts'));
    querySnapshot.forEach((doc) => {
      this.contacts = doc.data();
      this.contactList.push(this.contacts);
      this.cdr.detectChanges();
      // console.log(this.contactList);
    });
  }

  signOut() {
    signOut(this.auth)
      .then(() => {
        // Sign-out successful.
        this.router.navigateByUrl('auth/signin');
      })
      .catch((error) => {
        // An error happened.
      });
  }

  onClickAccordion(i: number, item: any) {
    console.log('accordion', i, item);
  }

  async onDeleteJoin(item: any) {
    console.log('delete', item.id);
    await deleteDoc(doc(db, 'joins', item.id));
    alert('Join dosyası silindi');
    this.cdr.detectChanges();
  }

  async onDeleteContact(item: any) {
    console.log('delete', item.id);
    await deleteDoc(doc(db, 'contacts', item.id));
    alert('Contact dosyası silindi');
    this.cdr.detectChanges();
  }

  contact() {
    this.activeButton = Buttons.Contact;
  }

  join() {
    this.activeButton = Buttons.Join;
  }

  download(item: any) {
    console.log(item);

    const fileRef = ref(storage, '/files/' + item.isim);
    console.log(fileRef);
    // Get the download URL
    getDownloadURL(fileRef)
      .then((url) => {
        // Insert url into an <img> tag to "download"
        console.log(url);
        
      })
      .catch((error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case 'storage/object-not-found':
            // File doesn't exist
            break;
          case 'storage/unauthorized':
            // User doesn't have permission to access the object
            break;
          case 'storage/canceled':
            // User canceled the upload
            break;

          // ...

          case 'storage/unknown':
            // Unknown error occurred, inspect the server response
            break;
        }
      });
  }
}
