import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tacsiz-prenses',
  templateUrl: './tacsiz-prenses.component.html',
  styleUrls: ['./tacsiz-prenses.component.scss']
})
export class TacsizPrensesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
