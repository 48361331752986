import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hayaller-hayatlar',
  templateUrl: './hayaller-hayatlar.component.html',
  styleUrls: ['./hayaller-hayatlar.component.scss']
})
export class HayallerHayatlarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
