<div class="main">
  <div class="col-md-8 col-sm-12">
    <iframe
    src="https://tally.so/embed/3lgXom?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1"
    loading="lazy"
    width="100%"
    height="1497"
    frameborder="0"
    marginheight="0"
    marginwidth="0"
    title="Bize Başvurun!"
  ></iframe>
  </div>
  <script>
    var d = document,
      w = "https://tally.so/widgets/embed.js",
      v = function () {
        "undefined" != typeof Tally
          ? Tally.loadEmbeds()
          : d
              .querySelectorAll("iframe[data-tally-src]:not([src])")
              .forEach(function (e) {
                e.src = e.dataset.tallySrc;
              });
      };
    if ("undefined" != typeof Tally) v();
    else if (d.querySelector('script[src="' + w + '"]') == null) {
      var s = d.createElement("script");
      (s.src = w), (s.onload = v), (s.onerror = v), d.body.appendChild(s);
    }
  </script>
  <!-- <div class="col-md-8 col-sm-12" *ngIf="!isSent">
    <form #joinForm="ngForm">
      <div>
        <h3>Kişisel Bilgiler</h3>
        <div class="form-row my-3">
          <div class="form-group">
            <label for="isim">Ad Soyad *</label>
            <input
              [(ngModel)]="join.isim"
              type="text"
              class="form-control"
              name="isim"
              #isim="ngModel"
              required
            />
            <span
              class="text-danger my-1"
              *ngIf="
                (isim.touched || joinForm.submitted) && isim.errors?.required
              "
            >
              İsim boş bırakılamaz.
            </span>
          </div>
          <div class="form-group">
            <label for="cinsiyet">Cinsiyet *</label>
            <select
              [(ngModel)]="join.cinsiyet"
              class="form-select"
              name="cinsiyet"
              #cinsiyet="ngModel"
              required
            >
              <option selected disabled>Seçiniz</option>
              <option value="kadın">Kadın</option>
              <option value="erkek">Erkek</option>
            </select>
            <span
              class="text-danger my-1"
              *ngIf="
                (cinsiyet.touched || joinForm.submitted) &&
                cinsiyet.errors?.required
              "
            >
              Cinsiyet boş bırakılamaz.
            </span>
          </div>
        </div>
        <div class="form-row my-3">
          <div class="form-group">
            <label for="dogumgunu">Doğum Tarihi *</label>
            <input
              [(ngModel)]="join.dogumgunu"
              type="date"
              class="form-control"
              name="dogumgunu"
              #dogumgunu="ngModel"
              required
            />
            <span
            class="text-danger my-1"
            *ngIf="
              (dogumgunu.touched || joinForm.submitted) &&
              dogumgunu.errors?.required
            "
          >
            Doğum günü boş bırakılamaz.
          </span>
          </div>
          <div class="form-group">
            <label for="dogumyeri">Doğum Yeri</label>
            <input
              [(ngModel)]="join.dogumyeri"
              type="text"
              class="form-control"
              name="dogumyeri"
            />
          </div>
        </div>
        <div class="form-row my-3">
          <div class="form-group">
            <label for="boy">Boy(cm)</label>
            <input
              [(ngModel)]="join.boy"
              type="text"
              class="form-control"
              name="boy"
            />
          </div>
          <div class="form-group">
            <label for="kilo">Kilo</label>
            <input
              [(ngModel)]="join.kilo"
              type="text"
              class="form-control"
              name="kilo"
            />
          </div>
        </div>
        <div class="form-row my-3">
          <div class="form-group">
            <label for="sacrengi">Saç rengi</label>
            <input
              [(ngModel)]="join.sacrengi"
              type="text"
              class="form-control"
              name="sacrengi"
            />
          </div>
          <div class="form-group">
            <label for="gozrengi">Göz rengi</label>
            <input
              [(ngModel)]="join.gozrengi"
              type="text"
              class="form-control"
              name="gozrengi"
            />
          </div>
        </div>
        <div class="form-row my-3">
          <div class="form-group">
            <label for="egitim">Eğitim</label>
            <select [(ngModel)]="join.egitim" class="form-select" name="egitim">
              <option selected disabled>Seçiniz</option>
              <option value="İlköğretim">İlköğretim</option>
              <option value="Lise">Lise</option>
              <option value="Ön Lisans">Ön lisans</option>
              <option value="Lisans">Lisans</option>
              <option value="Yüksek Lisans">Yüksek Lisans</option>
              <option value="doktora">Doktora</option>
            </select>
          </div>
          <div class="form-group">
            <label for="meslek">Meslek</label>
            <input
              [(ngModel)]="join.meslek"
              type="text"
              class="form-control"
              name="meslek"
            />
          </div>
        </div>
        <div class="form-row my-3">
          <div class="form-group">
            <label for="tiyatroegitimi">Tiyatro Eğitimi</label>
            <input
              [(ngModel)]="join.tiyatroegitimi"
              type="text"
              class="form-control"
              name="tiyatroegitimi"
            />
          </div>
          <div class="form-group">
            <label for="ozelyetenek">Özel Yetenek</label>
            <input
              [(ngModel)]="join.ozelyetenek"
              type="text"
              class="form-control"
              name="ozelyetenek"
            />
          </div>
        </div>
      </div>
      <div class="my-3">
        <h3>İletişim Bilgileri</h3>
        <div class="form-row my-3">
          <div class="form-group">
            <label for="sehir">Şehir *</label>
            <input
              [(ngModel)]="join.sehir"
              type="text"
              class="form-control"
              name="sehir"
              #sehir="ngModel"
              required
            />
            <span
            class="text-danger my-1"
            *ngIf="
              (sehir.touched || joinForm.submitted) &&
              sehir.errors?.required
            "
          >
            Şehir boş bırakılamaz.
          </span>
          </div>
          <div class="form-group">
            <label for="ilce">İlçe *</label>
            <input
              [(ngModel)]="join.ilce"
              type="text"
              class="form-control"
              name="ilce"
              #ilce="ngModel"
              required
            />
            <span
            class="text-danger my-1"
            *ngIf="
              (ilce.touched || joinForm.submitted) &&
              ilce.errors?.required
            "
          >
            İlçe boş bırakılamaz.
          </span>
          </div>
        </div>
        <div class="form-row my-3">
          <div class="form-group">
            <label for="telefon">Telefon *</label>
            <input
              [(ngModel)]="join.telefon"
              type="text"
              class="form-control"
              name="telefon"
              #telefon="ngModel"
              required
            />
            <span
            class="text-danger my-1"
            *ngIf="
              (telefon.touched || joinForm.submitted) &&
              telefon.errors?.required
            "
          >
            Telefon numarası boş bırakılamaz.
          </span>
          </div>
          <div class="form-group">
            <label for="email">Mail *</label>
            <input
              [(ngModel)]="join.email"
              type="string"
              class="form-control"
              name="email"
              #email="ngModel"
              required
            />
            <span
            class="text-danger my-1"
            *ngIf="
              (email.touched || joinForm.submitted) &&
              email.errors?.required
            "
          >
            Email adresi boş bırakılamaz.
          </span>
          </div>
        </div>
      </div>
      <div class="my-3">
        <h3>Fotoğraf & Video</h3>
        <p>Lütfen 3 adet fotoğraf ve 1 adet videonuzu yükleyiniz.</p>
        <div class="form-row my-3">
          <div class="form-group">
            <label for="media1" class="fake-btn"
              >Fotoğraf Seç {{ progress1 }} %</label
            >
            <input
              style="display: none"
              [(ngModel)]="join.media1"
              type="file"
              class="form-control-file"
              id="media1"
              name="media1"
              (change)="uploadfile1($event.target.files)"
            />
          </div>
          <div class="form-group">
            <label for="media2" class="fake-btn"
              >Fotoğraf Seç {{ progress2 }} %</label
            >
            <input
              style="display: none"
              [(ngModel)]="join.media2"
              type="file"
              class="form-control-file"
              id="media2"
              name="media2"
              (change)="uploadfile2($event.target.files)"
            />
          </div>
          <div class="form-group">
            <label for="media3" class="fake-btn"
              >Fotoğraf Seç {{ progress3 }} %</label
            >
            <input
              style="display: none"
              [(ngModel)]="join.media3"
              type="file"
              class="form-control-file"
              id="media3"
              name="media3"
              (change)="uploadfile3($event.target.files)"
            />
          </div>
          <div class="form-group">
            <label for="media4" class="fake-btn"
              >Video Seç {{ progress4 }} %</label
            >
            <input
              style="display: none"
              [(ngModel)]="join.media4"
              type="file"
              class="form-control-file"
              name="media4"
              (change)="uploadfile4($event.target.files)"
            />
          </div>
        </div>
      </div>
      <button type="submit" class="btn submit-button my-3" (click)="submit()">
        Formu Gönder
      </button>
    </form>
  </div> -->
  <!-- <div class="col-md-8" *ngIf="isSent">
    <div
      class="d-flex flex-row justify-content-center align-items-center text-center my-5"
    >
      <h3>Formunuz bize ulaştı. En kısa sürede görüşmek üzere.</h3>
    </div>
  </div> -->
</div>
