import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ariza',
  templateUrl: './ariza.component.html',
  styleUrls: ['./ariza.component.scss']
})
export class ArizaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
