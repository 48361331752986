<div class="d-flex flex-column justify-content-center align-items-center row">
  <div class="col-md-8">
    <div class="list">
      <div class="news-box my-3">
        <div class="news-text">
          <h2>Taçsız Prenses</h2>
          <h4>Yapımını MF Yapım’ın üstlendiği, yönetmen koltuğunda Volkan Keskin’in oturduğu ve senaryosunu Pınar Bulut ile Onur Koralp’in yazdığı “Taçsız Prenses” dizisinin merakla beklenen afişi yayınlandı.
          </h4>
          <div class="news-image">
            <img
              src="../../../../assets/casting/2023/tacsizprens.jpeg"
              alt=""
            />
          </div>
          <p>
            10 Ocak Salı günü ilk bölümüyle FOX izleyicisiyle buluşacak dizinin kadrosunda; İsmail Hacıoğlu, Sümeyye Aydoğan, Tolga Tekin, Elif Kurtaran, Seda Akman, Mustafa Kırantepe, Feride Çetin, Merve Bulut ve Merve Şen gibi birbirinden çok sevilen oyuncular yer alıyor.
          </p>
          <p>
            Taçsız Prenses’te; Hayatı boyunca hayalperest annesi Şirin’in (Feride Çetin) masallarıyla büyüyen Masal (Elif Kurtaran), annesinin kalp hastalığı nedeniyle hastaneye kaldırılmasıyla soğuk ve gerçekçi bir dünyanın içine hapsolur ve alabildiğine yırtıcı, hırçın, her kötülüğü yapabilecek bir kıza dönüşür. Masal’ın ilk destek göreceği kişi öğretmeni Evgin (İsmail Hacıoğlu) olurken işler zamanla herkes için tersine dönecektir. Kahramanımız Masal yolculuğu boyunca, bir yandan kendi masalını ararken bir yandan da hikayenin diğer kahramanlarının yarım kalmış masallarını tek tek tamamlayacak ve bu yolculukta Masal’ın en yakını ise yanından ayrılmayan köpeği Köfte olacaktır…
          </p>
          <p>
            Karanlığın içinde umudu bulma hikayesi “Taçsız Prenses”, 10 Ocak Salı saat 20.00’de ilk bölümüyle FOX’ta.
          </p>
          <a
            href="https://www.fox.com.tr/daha-daha-fox/ozel-haberler/3548/tacsiz-prensesin-afisi-yayinlandi"
            target="_blank"
            >Kaynak</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
