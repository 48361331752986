<div class="d-flex flex-column justify-content-center align-items-center row">
  <div class="col-md-8">
    <div class="list">
      <div class="news-box my-3">
        <div class="news-text">
          <h2>Kusursuz Kiracı</h2>
          <h4>Kusursuz Kiracı İlk Bölümüyle 30 Ağustos'ta FOX'ta!</h4>
          <div class="news-image">
            <img
              src="../../../../assets/casting/2022/kusursuzkiraci.jpg"
              alt=""
            />
          </div>
          <p>
            Tanıtımlarıyla dikkatleri üzerine çeken “Kusursuz Kiracı”, 30
            Ağustos Salı günü ilk bölümüyle FOX izleyicisiyle buluşmaya
            hazırlanıyor.
          </p>
          <p>
            Dilan Çiçek Deniz, Serkay Tütüncü, Melisa Döngel, Özlem Tokaslan,
            Ruhi Sarı, Beyti Engin, Deniz Cengiz, Umut Kurt, Nilsu Yılmaz, Rami
            Narin, Ümmü Putgül, Alptekin Serdengeçti, Şirin Öten, Hasan
            Şahintürk, Rüçhan Çalışkur ve Bennu Yıldırımlar gibi güçlü isimlerin
            kadrosunda yer aldığı dizinin merakla beklenen afişi yayınlandı,
            kısa sürede sosyal medyada gündem oldu.
          </p>
          <p>
            Dilan Çiçek Deniz’in hayat verdiği ana karakterin (Mona) yeni
            taşındığı apartmandaki gizemli olayları konu alacak “Kusursuz
            Kiracı”nın yapımcılığını MF YAPIM üstlenirken, yönetmen koltuğunda
            Yusuf Pirhasan oturuyor ve yazar Nermin Yıldırım’a ait “Ev”
            romanındaki “Evi Yak” öyküsünden hareketle kaleme alıyor.
          </p>
          <p>
            Konusuyla fark yaratan “Kusursuz Kiracı”, 30 Ağustos Salı saat
            20.00’de ilk bölümüyle FOX’ta.
          </p>
          <a
            href="https://www.fox.com.tr/Kusursuz-Kiraci/haber/3458/Kusursuz-Kiraci-Ilk-Bolumuyle-30-Agustos-ta-FOX-ta"
            target="_blank"
            >Kaynak</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
