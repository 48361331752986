import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-kardeslerim',
  templateUrl: './kardeslerim.component.html',
  styleUrls: ['./kardeslerim.component.scss']
})
export class KardeslerimComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
