<div class="container">
  <div class="d-flex flex-row justify-content-center align-items-center my-3">
    <h1 class="title">Sems's Casting</h1>
  </div>
  <div class="d-flex justify-content-center">
    <div class="my-5 login">
      <h1 class="text-center my-3">Reset Password</h1>
      <hr />
  
      <div class="my-1">
        <label for="email" class="form-label">Email</label>
        <input
          [(ngModel)]="user.email"
          type="email"
          class="form-control"
          id="email"
          aria-describedby="emailHelp"
        />
      </div>
      <button type="submit" class="btn signin my-3" (click)="reset()">
        Submit
      </button>

    </div>
  </div>
  
</div>