<div class="d-flex flex-column justify-content-center align-items-center row">
  <div class="col-md-8">
    <div class="list">
      <div class="news-box my-3">
        <div class="news-text">
          <h2>Mahkum</h2>
          <h4>
            FOX'un Yeni Dizisi Mahkum'un Merakla Beklenen Afişi Yayınlandı!
          </h4>
          <div class="news-image">
            <img src="https://tejedd76pluu.merlincdn.net/resize/1080x608/Programlar/Mahkum/Haberler/Mahkum-haber-resim-3b5a4132-2654-4e7a-a633-9be1dcdce6b5.jpg" alt="" />
          </div>
          <p>
            FOX izleyicisiyle buluşmaya hazırlanan, MF Yapım imzalı yepyeni dizi
            “Mahkum”un merakla beklenen afişi yayınlandı. “Innocent Defendant”
            dizisinin uyarlaması olarak ekrana gelecek “Mahkum”un afişinde
            başrollerden Onur Tuna ve İsmail Hacıoğlu boy gösteriyor.
            Görselliğiyle dikkat çeken, sosyal medyada kısa süre içerisinde çok
            konuşulan afişteki “Her hikayenin iki yüzü ama tek gerçeği vardır”
            sözü de büyük merak uyandırdı.
          </p>
          <p>
            Yönetmen koltuğunda Volkan Kocatürk’ün oturduğu, senaryosunu Uğraş
            Güneş’in uyarladığ, başrollerini Onur Tuna, İsmail Hacıoğlu, Seray
            Kaya ve Melike İpek Yalova’nın üstlendiği dizide Hakan Karsak, Anıl
            İlter, Tugay Mercan, Hayal Köseoğlu, Muharrem Türkseven, Bülent
            Seyran, Furkan Kalabalık, Burcu Cavrar, Alara Bozbey, Gürberk Polat,
            Murat Şahan, Gökhan Tercan, Alya Sude Mazak, İlker Yağız Uysal,
            Nihal Koldaş, Bülent Düzgünoğlu, Mehmet Ulay ve Hakan Salınmış gibi
            ünlü isimler yer alıyor. Dikkatleri üzerine çekmeye devam eden
            dizide; İdealist bir Cumhuriyet Savcısı olan Fırat Bulut (Onur
            Tuna), bir sabah uyandığında kendini cezaevinde bulur. Hiçbir şey
            hatırlamıyordur ve eşiyle kızını öldürmekten yargılanıyordur…
            Hapsedilmesinden önce araştırdığı en son dava, ülkenin en önde gelen
            ailelerinden olan Yesari'lerin halefleri olan ikiz kardeşlerden,
            Barış Yesari'nin davasıdır… Kendisine kurulan komployla bu çıkmaza
            düşen Fırat, masumiyetini kanıtlayabilecek midir?
          </p>
          <p>“Mahkum”, çok yakında FOX’ta.</p>
          <a href="https://www.fox.com.tr/Mahkum/haber/3241/FOX-un-Yeni-Dizisi-Mahkum-un-Merakla-Beklenen-Afisi-Yayinlandi" target="_blank">Kaynak</a>
        </div>
      </div>
    </div>
  </div>
</div>
