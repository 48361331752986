import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
})
export class NewsComponent implements OnInit {
  newsList = [
    {
      title: 'TAÇSIZ PRENS',
      subtitle:
        'Yapımını MF Yapım’ın üstlendiği, yönetmen koltuğunda Volkan Keskin’in oturduğu ve senaryosunu Pınar Bulut ile Onur Koralp’in yazdığı “Taçsız Prenses” dizisinin merakla beklenen afişi yayınlandı.',
      text: '10 Ocak Salı günü ilk bölümüyle FOX izleyicisiyle buluşacak dizinin kadrosunda; İsmail Hacıoğlu, Sümeyye Aydoğan, Tolga Tekin, Elif Kurtaran, Seda Akman, Mustafa Kırantepe, Feride Çetin, Merve Bulut ve Merve Şen gibi birbirinden çok sevilen oyuncular yer alıyor. Taçsız Prenses’te; Hayatı boyunca hayalperest annesi Şirin’in (Feride Çetin) masallarıyla büyüyen Masal (Elif Kurtaran), annesinin kalp hastalığı nedeniyle hastaneye kaldırılmasıyla soğuk ve gerçekçi bir dünyanın içine hapsolur ve alabildiğine yırtıcı, hırçın, her kötülüğü yapabilecek bir kıza dönüşür...',
      route: 'tacsiz-prenses',
      image: '../../../assets/casting/2023/tacsizprens.jpeg',
    },
    {
      title: 'DÜNYAYLA BENİM ARAMDA',
      subtitle:
        "Yönetmenliğini Hülya Gezer’in üstlendiği, senaryosunu Pınar Bulut’un yazdığı Disney Plus'ın yeni yerli dizisi 'Dünyayla Benim Aramda'nın yayın tarihi 14 Eylül olarak belirlendi.  Yapımcılığını MF Yapım’ın üstlendiği dizinin oyuncu kadrosunda, Demet Özdemir, Buğra Gülsoy, Hafsanur Sancaktutan, Metin Akdülger, İbrahim Selim, Melisa Döngel, Ali Yoğurtçuoğlu ve Zerrin Tekindor gibi isimler yer alıyor.",
      text: '‘Dünyayla Benim Aramda’, büyük bir aşk ve tutkuyla başlayan ilişkisinin artık eskisi gibi olmadığını fark eden bir kadının ilişkisini kurtarmak adına sosyal medya üzerinden oynadığı oyunu ve beraberinde yaşananları bağlılık kavramı üzerinden ele alıyor.',
      route: 'dunyayla-benim-aramda',
      image: '../../../assets/casting/2022/dba.jpeg',
    },
    {
      title: 'BİR KÜÇÜK GÜN IŞIĞI',
      subtitle: '"Bir kadın bir adamı ne kadar sevebilir?"',
      text: "Senaryosunu Özgür Evren Heptürk'ün kaleme aldığı yönetmen koltuğunda ise Emre Kabakuşak'ın oturduğu dizi, tüm ezberleri bozarak her şeye yeniden başlamak zorunda kalan Elif'in karanlıktan gün ışığına çıkma hikayesini konu alıyor. 10 yıldır bir yalanın içinde yaşayan Elif'in hayatı bir haberle alt üst olur. Çaresiz genç kadının yolu zengin iş adamı Fırat ile beklenmedik bir şekilde kesiştiğinde ise her şey daha da karışır. Yürekleri ısıtan anlatımı ve kalplere dokunan hikayesi ile 'Bir Küçük Gün Işığı' Eylül'de atv'de.",
      route: 'bir-kucuk-gun-isigi',
      image: '../../../assets/casting/2022/gunisigi.webp',
    },
    {
      title: 'KUSURSUZ KİRACI',
      subtitle: "Kusursuz Kiracı İlk Bölümüyle 30 Ağustos'ta FOX'ta!",
      text: 'Tanıtımlarıyla dikkatleri üzerine çeken “Kusursuz Kiracı”, 30 Ağustos Salı günü ilk bölümüyle FOX izleyicisiyle buluşmaya hazırlanıyor.',
      route: 'kusursuz-kiraci',
      image: '../../../assets/casting/2022/kusursuzkiraci.jpg',
    },
    {
      title: 'HAYALLER VE HAYATLAR',
      subtitle:
        'Hayallerinin peşinde koşarken hayata yakalananların hikayesini anlatan. beIN Originals etiketli yeni dizi “Hayaller ve Hayatlar” 17 Şubatta sizlerle…',
      text: 'Hayallerinin peşinde koşarken hayata yakalananların hikayesini anlatan beIN Originals etiketli dizi, dört yakın arkadaşın hayallerini gerçekleştirme çabasına odaklanıyor.Hayalleri ve hayatları uymayan genç kadınların mücadelesi, bir cinayet haberiyle daha da karmaşıklaşıyor.',
      route: 'hayaller-hayatlar',
      image: '../../../assets/casting/2022/hvh.webp',
    },
    {
      title: 'MAHKUM',
      subtitle:
        "FOX'un Yeni Dizisi Mahkum'un Merakla Beklenen Afişi Yayınlandı!",
      text: 'FOX izleyicisiyle buluşmaya hazırlanan, MF Yapım imzalı yepyeni dizi “Mahkum”un merakla beklenen afişi yayınlandı.“Innocent Defendant” dizisinin uyarlaması olarak ekrana gelecek “Mahkum”un afişinde başrollerden Onur Tuna ve İsmail Hacıoğlu boy gösteriyor.',
      route: 'mahkum',
      image: '../../../assets/casting/2021/mahkum.webp',
    },
    {
      title: 'KADERİMİN OYUNU',
      subtitle: 'Kaderimin Oyunu’nun Afişi Yayınlandı',
      text: 'Star’ın yeni dizisi ‘Kaderimin Oyunu’’nun merakla beklenen afişi yayınlandı. Nefes kesen hikayesi ve güçlü oyuncu kadrosuyla yakında ekrana gelecek olan dizinin afişi büyük merak uyandırdı. ',
      route: 'kaderimin-oyunu',
      image: '../../../assets/casting/2021/ko.webp',
    },
    {
      title: 'EVLİLİK HAKKINDA HER ŞEY',
      subtitle: 'Evlilik Hakkında Her Şey”in posteri yayınlandı!',
      text: 'Yönetmen koltuğunda Yusuf Pirhasan’ın oturduğu, senaryosunu Seda Çalışır Karaoğlu, Toprak Karaoğlu ve Ayşe Canan Ertuğ’nun uyarladığı, MF Yapım imzalı FOX’un yeni dizisi “Evlilik Hakkında Her Şey”in afişi yayınlandı. “Evlilik Hakkında Her Şey” ilk bölümü ile 21 Eylül Salı saat 20.00’de FOX ekranlarında izleyicisiyle buluşacak.',
      route: 'evlilik-hakkinda-her-sey',
      image: '../../../assets/casting/2021/evlilikhakkindahersey.jpeg',
    },
    {
      title: 'KARDEŞLERİM',
      subtitle:
        "atv'nin yeni dizisi Kardeşlerim'in merakla beklenen afişi yayınlandı",
      text: "Yapımına NGM'in imza attığı yönetmenliğini Serkan Birinci'nin üstlendiği, senaryosunu Gül Abus Semerci'nin kaleme aldığı atv'nin ilgi uyandıran dizisi 'Kardeşlerim' in afişi yayınlandı.",
      route: 'kardeslerim',
      image: '../../../assets/casting/2021/kardeslerim.webp',
    },
    {
      title: 'ARIZA',
      subtitle: "Arıza'nın afişi yayınlandı!",
      text: "Show TV’nin yeni sezon dizisi, O3 Medya imzasını taşıyan, başrollerini Tolga Sarıtaş ve Ayça Ayşin Turan’ın paylaştığı 'Arıza'Pazar akşamı izleyici ile buluşuyor. Ekran için gün sayan dizinin afişi de yayınlandı.",
      route: 'ariza',
      image: '../../../assets/casting/2020/ariza2020.jpg',
    },
    {
      title: 'SEFİRİN KIZI',
      subtitle:
        'Star’ın iddialı projelerinden Sefirin Kızı’nın afişi yayınlandı. Engin Akyürek ve Neslihan Atagül Doğulu’nun ilk kez partner olarak izleyicinin karşısına çıkacağı dizinin afişi büyük beğeni topladı.',
      text: 'Engin Akyürek ve Neslihan Atagül Doğulu’nun ilk kez partner olarak izleyicinin karşısına çıkacağı Star’ın iddialı projelerinden Sefirin Kızı’nın afişi yayınlandı.',
      route: 'sefirin-kizi',
      image: '../../../assets/casting/2019/sefirinkizi2019.webp',
    },
    // {
    //   title: "ZALİM İSTANBUL",
    //   subtitle: "Zalim İstanbul'un ilk tanıtımı yayınlandı! Kanal D ‘de Pazartesi izleyicisi ile buluşacak olan Zalim İstanbul’un ilk tanıtımı yayınlandı!",
    //   text: "Kanal D'de yakında izleyicisi ile buluşacak olan, yapımını Avşar Film’in, yapımcılığını Şükrü Avşar’ın üstlendiği, yönetmen koltuğunda Cevdet Mercan’ın oturduğu, öykü ve senaryosunu Sırma Yanık’ın kaleme aldığı 'Zalim İstanbul'un ilk tanıtımı yayınlandı.",
    //   route: "",
    //   image: '../../../assets/casting/2019/zalimistanbul.jpeg',
    // },
  ];
  constructor() {}

  ngOnInit(): void {}
}
