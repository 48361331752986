<div class="d-flex flex-column justify-content-center align-items-center row">
  <div class="col-md-8">
    <div class="list">
      <div class="news-box my-3">
        <div class="news-text">
          <h2>Evlilik Hakkında Her Şey</h2>
          <h4>“Evlilik Hakkında Her Şey”in posteri yayınlandı!</h4>
          <div class="news-image">
            <img
              src="https://tejedd76pluu.merlincdn.net/resize/1280x720/Programlar/Evlilik-Hakkinda-Her-Sey/Haberler/Evlilik-Hakkinda-Her-Sey-haber-resim-82aa3c57-443b-45d8-8588-bad9c38ef2c2.jpg"
              alt=""
            />
          </div>
          <p>
            Yönetmen koltuğunda Yusuf Pirhasan’ın oturduğu, senaryosunu Seda
            Çalışır Karaoğlu, Toprak Karaoğlu ve Ayşe Canan Ertuğ’nun
            uyarladığı, MF Yapım imzalı FOX’un yeni dizisi “Evlilik Hakkında Her
            Şey”in afişi yayınlandı. “Evlilik Hakkında Her Şey” ilk bölümü ile
            21 Eylül Salı saat 20.00’de FOX ekranlarında izleyicisiyle
            buluşacak.
          </p>
          <p>
            Evlilik ve ilişkilerin kirli düzenini ortaya çıkaracak olan “Evlilik
            Hakkında Her Şey”in posterinde Gökçe Bahadır, Sarp Akkaya, Yiğit
            Kirazcı, Gökçe Eyüboğlu, Tülin Ece ve Sumru Yavrucuk boy gösteriyor.
          </p>
          <p>
            BBC yapımı, “The Split” dizisinin uyarlaması olarak ekrana gelecek
            “Evlilik Hakkında Her Şey”in kadrosunda; Gökçe Bahadır, Sarp Akkaya,
            Yiğit Kirazcı, Gökçe Eyüboğlu, Fırat Altunmeşe, Tülin Ece, Bertan
            Asllani, Cevdet Arıcılar, Esma Yılmaz, Rüzgar Doruk Özmil, Emine Ada
            Sezer, Mila Kasarcı ve Erdal Küçükkömürcü ve Sumru Yavrucuk gibi
            birbirinden başarılı isimler yer alıyor. Cevher ailesine mensup
            birbirinden çok farklı dört kadının ortak geçmişlerine olan
            tepkileri ve farklılıklarının işleneceği ve bir evliliğin bütün
            aşamalarını bu dört kadın kahramanımız üzerinden göreceğimiz dizide;
            Çolpan (Sumru Yavrucuk) boşanmış, Azra (Gökçe Bahadır) uzun
            yıllardır evli, Güneş (Tülin Ece) evlilik arifesinde ve Sanem (Gökçe
            Eyüboğlu) ise evlilik kavramını tümüyle reddetmektedir.
            Müvekkillerin hikayelerinin de dahil olmasıyla “Evlilik Hakkında Her
            Şey”; aile, aşk ve sadakat kavramlarını ön plana çıkarırken
            boşanmanın kirli düzenini gözler önüne sermekten çekinmeyen
            hikayeleriyle 21 Eylül’de ekrana gelecek.
          </p>
          <p>
            Tanıtımlarıyla büyük merak uyandıran ve çekimleri İstanbul’da devam
            eden dizi “Evlilik Hakkında Her Şey”, 21 Eylül’de FOX’ta.
          </p>
          <a
            href="https://www.fox.com.tr/Evlilik-Hakkinda-Her-Sey/haber/3147/Evlilik-Hakkinda-Her-Sey-in-posteri-yayinlandi"
            target="_blank"
            >Kaynak</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
