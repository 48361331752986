import { Component, OnInit } from '@angular/core';
import { IJoin, Join } from 'src/app/core/models/join.model';
import {
  doc,
  addDoc,
  updateDoc,
  collection,
  getFirestore,
} from 'firebase/firestore';
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from 'firebase/storage';
import { getAuth, signInAnonymously } from 'firebase/auth';

const db = getFirestore();
const storage = getStorage();
const auth = getAuth();
@Component({
  selector: 'app-join',
  templateUrl: './join.component.html',
  styleUrls: ['./join.component.scss'],
})
export class JoinComponent implements OnInit {
  join: IJoin = new Join();
  date: Date = new Date();
  counter = 0;
  fileToUpload: File | any = null;
  progress1: number = 0;
  progress2: number = 0;
  progress3: number = 0;
  progress4: number = 0;

  isSent: boolean = false;
  constructor() {
    signInAnonymously(auth)
    .then(() => {
      // Signed in..
      console.log('anonymously logged in');
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      // ...
    });
  }

  ngOnInit(): void {}

  uploadfile1(files: FileList) {
    console.log('files & item', files);
    this.fileToUpload = files[0];
    console.log(this.fileToUpload);
    const storageRef = ref(
      storage,
      `/files/${this.join.isim}/${this.fileToUpload?.name}`
    );
    const uploadTask = uploadBytesResumable(storageRef, this.fileToUpload);
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        this.progress1 = progress;
      },
      (err) => console.log(err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          console.log(url);
          this.join.media1 = url;
        });
      }
    );
  }
  uploadfile2(files: FileList) {
    console.log('files & item', files);
    this.fileToUpload = files[0];
    console.log(this.fileToUpload);
    const storageRef = ref(
      storage,
      `/files/${this.join.isim}/${this.fileToUpload?.name}`
    );
    const uploadTask = uploadBytesResumable(storageRef, this.fileToUpload);
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        this.progress2 = progress;
      },
      (err) => console.log(err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          console.log(url);
          this.join.media2 = url;
        });
      }
    );
  }
  uploadfile3(files: FileList) {
    console.log('files & item', files);
    this.fileToUpload = files[0];
    console.log(this.fileToUpload);
    const storageRef = ref(
      storage,
      `/files/${this.join.isim}/${this.fileToUpload?.name}`
    );
    const uploadTask = uploadBytesResumable(storageRef, this.fileToUpload);
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        this.progress3 = progress;
      },
      (err) => console.log(err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          console.log(url);
          this.join.media3 = url;
        });
      }
    );
  }
  uploadfile4(files: FileList) {
    console.log('files & item', files);
    this.fileToUpload = files[0];
    console.log(this.fileToUpload);
    const storageRef = ref(
      storage,
      `/files/${this.join.isim}/${this.fileToUpload?.name}`
    );
    const uploadTask = uploadBytesResumable(storageRef, this.fileToUpload);
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        this.progress4 = progress;
      },
      (err) => console.log(err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          console.log(url);
          this.join.media4 = url;
        });
      }
    );
  }

  async submit() {
    console.log('Form: ', this.join);
    try {
      const docRef = await addDoc(collection(db, 'joins'), {
        isim: this.join?.isim,
        cinsiyet: this.join?.cinsiyet,
        dogumgunu: this.join?.dogumgunu,
        dogumyeri: this.join?.dogumyeri,
        boy: this.join?.boy,
        kilo: this.join?.kilo,
        sacrengi: this.join?.sacrengi,
        gozrengi: this.join?.gozrengi,
        egitim: this.join?.egitim,
        meslek: this.join?.meslek,
        tiyatroegititmi: this.join?.tiyatroegitimi,
        ozelyetenek: this.join?.ozelyetenek,
        sehir: this.join?.sehir,
        ilce: this.join?.ilce,
        telefon: this.join?.telefon,
        email: this.join?.email,
        media1: this.join?.media1,
        media2: this.join?.media2,
        media3: this.join?.media3,
        media4: this.join?.media4,
        tarih: this.date
      });
      console.log('Document written with ID: ', docRef.id);
      const docRef2 = doc(db, "contacts", docRef.id)
      await updateDoc(docRef2, {
        id: docRef.id
      })
      this.isSent = true;
    } catch (e) {
      console.error('Error adding document: ', e);
      this.isSent = false;
    }
  }
}
