<div class="container">
  <div class="d-flex flex-row justify-content-center align-items-center">
    <h1 class="title">Sem's Casting</h1>
  </div>

  <div class="d-flex justify-content-center">
    <div class="my-5 login">
      <h1 class="text-center my-3">Sign up</h1>
      <hr />

      <div class="my-1">
        <label for="email" class="form-label">Email</label>
        <input
          [(ngModel)]="user.email"
          type="email"
          class="form-control"
          id="email"
          aria-describedby="emailHelp"
        />
      </div>
      <div class="my-1">
        <label for="password" class="form-label">Password</label>
        <input
          [(ngModel)]="user.password"
          type="password"
          class="form-control"
          id="password"
        />
      </div>
      <button type="submit" class="btn signin my-3" (click)="signup()">
        Submit
      </button>
      <div class="my-1 text-center">
        <a routerLink="../signin" class="text">Already signup? Signin</a>
      </div>
    </div>
  </div>
</div>
