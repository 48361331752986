import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProfileComponent } from './auth/profile/profile.component';
import { ResetComponent } from './auth/reset/reset.component';
import { SigninComponent } from './auth/signin/signin.component';
import { SignupComponent } from './auth/signup/signup.component';
import { AboutComponent } from './pages/about/about.component';
import { BookComponent } from './pages/book/book.component';
import { CastingComponent } from './pages/casting/casting.component';
import { ContactComponent } from './pages/contact/contact.component';
import { HomeComponent } from './pages/home/home.component';
import { JoinComponent } from './pages/join/join.component';
import { ManagementComponent } from './pages/management/management.component';
import { ArizaComponent } from './pages/news/ariza/ariza.component';
import { BirKucukGunIsigiComponent } from './pages/news/bir-kucuk-gun-isigi/bir-kucuk-gun-isigi.component';
import { DunyaylaBenimAramdaComponent } from './pages/news/dunyayla-benim-aramda/dunyayla-benim-aramda.component';
import { EvlilikHakkindaHerSeyComponent } from './pages/news/evlilik-hakkinda-her-sey/evlilik-hakkinda-her-sey.component';
import { HayallerHayatlarComponent } from './pages/news/hayaller-hayatlar/hayaller-hayatlar.component';
import { KaderiminOyunuComponent } from './pages/news/kaderimin-oyunu/kaderimin-oyunu.component';
import { KardeslerimComponent } from './pages/news/kardeslerim/kardeslerim.component';
import { KusursuzKiraciComponent } from './pages/news/kusursuz-kiraci/kusursuz-kiraci.component';
import { MahkumComponent } from './pages/news/mahkum/mahkum.component';
import { NewsComponent } from './pages/news/news.component';
import { SefirinKiziComponent } from './pages/news/sefirin-kizi/sefirin-kizi.component';
import { TacsizPrensesComponent } from './pages/news/tacsiz-prenses/tacsiz-prenses.component';
import { PagenotfoundComponent } from './pages/pagenotfound/pagenotfound.component';

const routes: Routes = [
  // { path: '', pathMatch: 'full', redirectTo: 'home' },
  { path: '', component: HomeComponent },
  { path: 'about', component: AboutComponent },
  { path: 'casting', component: CastingComponent },
  { path: 'management', component: ManagementComponent },
  // { path: 'book', component: BookComponent },
  { path: 'news', component: NewsComponent},
  { path: 'news/hayaller-hayatlar', component: HayallerHayatlarComponent },
  { path: 'news/mahkum', component: MahkumComponent },
  { path: 'news/kaderimin-oyunu', component: KaderiminOyunuComponent },
  { path: 'news/evlilik-hakkinda-her-sey', component: EvlilikHakkindaHerSeyComponent},
  { path: 'news/kardeslerim', component: KardeslerimComponent },
  { path: 'news/ariza', component: ArizaComponent },
  { path: 'news/sefirin-kizi', component: SefirinKiziComponent },
  { path: 'news/tacsiz-prenses', component: TacsizPrensesComponent },
  { path: 'news/dunyayla-benim-aramda', component: DunyaylaBenimAramdaComponent },
  { path: 'news/bir-kucuk-gun-isigi', component: BirKucukGunIsigiComponent },
  { path: 'news/kusursuz-kiraci', component: KusursuzKiraciComponent },
  { path: 'join', component: JoinComponent },
  { path: 'contact', component: ContactComponent },
  {
    path: 'auth',
    children: [
      { path: 'signin', component: SigninComponent },
      { path: 'signup', component: SignupComponent },
      { path: 'reset', component: ResetComponent },
      { path: 'profile', component: ProfileComponent },
    ],
  },
  { path:'**', pathMatch: 'full', component:PagenotfoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
