export interface IContact {
  _id?: string;
  isim?: string;
  name?: string;
  telefon?: string;
  email?: string;
  konu?: string;
  mesaj?: string;
  tarih?: Date;
}

export class Contact implements IContact {
  _id?: string;
  isim?: string;
  name?: string;
  telefon?: string;
  email?: string;
  konu?: string;
  mesaj?: string;
  tarih?: Date;
}