<!-- <div class="d-flex flex-column justify-content-center align-items-center row">
  <div class="col-md-8">
    <h1></h1>
    <div class="card-container mb-5">
      <div *ngFor="let card of cardList">
        <div class="card" style="background-image: url('{{ card.src }}')">
          <div class="wrap">
            <h2>{{ card.name }}</h2>
            <div class="icons">
              <a href="{{ card.ig }}" target="_blank">
                <i class="fab fa-instagram" aria-hidden="true"></i>
              </a>
              <a href="{{ card.tw }}" target="_blank">
                <i class="fab fa-twitter" aria-hidden="true"></i>
              </a>
              <a href="{{ card.tik }}" target="_blank">
                <i class="fab fa-tiktok" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<div class="d-flex flex-column justify-content-center align-items-center h-50">
  <p class="const">Yapım Aşamasında</p>
  <div class="lds-spinner my-3">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>

