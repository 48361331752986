import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-casting',
  templateUrl: './casting.component.html',
  styleUrls: ['./casting.component.scss'],
})
export class CastingComponent implements OnInit {
  lists = [
    {
      year: '2023',
      movies: [
        {
          title: 'Taçsız Prenses',
          src: '../../../assets/casting/2023/tacsizprens.jpeg',
          type: 'dizi',
          imdb: '8.5',
          imdbLink: 'https://www.imdb.com/title/tt22727856/',
        }
      ],
    },
    {
      year: '2022',
      movies: [
        {
          title: 'Kusursuz Kiracı',
          src: '../../../assets/casting/2022/kusursuzkiraci.jpg',
          type: 'dizi',
          imdb: '6.8',
          imdbLink: 'https://www.imdb.com/title/tt19383446/',
        },
        {
          title: 'Bir Küçük Gün Işığı',
          src: '../../../assets/casting/2022/gunisigi.webp',
          type: 'dizi',
          imdb: '6.7',
          imdbLink: 'https://www.imdb.com/title/tt21105044/',
        },
        {
          title: 'Dünyayla Benim Aramda',
          src: '../../../assets/casting/2022/dba.jpeg',
          type: 'dizi',
          imdb: '5.6',
          imdbLink: 'https://www.imdb.com/title/tt17678852/',
        },
        {
          title: 'Hayaller ve Hayatlar',
          src: '../../../assets/casting/2022/hvh.webp',
          type: 'dizi',
          imdb: '9.1',
          imdbLink: 'https://www.imdb.com/title/tt16162676/',
        },
        {
          title: 'Aşk Taktikleri',
          src: '../../../assets/casting/2022/asktatiktikleri.webp',
          type: 'dizi',
          imdb: '5.3',
          imdbLink: 'https://www.imdb.com/title/tt14486678/',
        }
      ],
    },
    {
      year: '2021',
      movies: [
        {
          title: 'Evlilik Hakkında Her Şey',
          src: '../../../assets/casting/2021/evlilikhakkindahersey.jpeg',
          imdb: '6.7',
          imdbLink: 'https://www.imdb.com/title/tt15023948/',
        },
        {
          title: 'Kardeşlerim',
          src: '../../../assets/casting/2021/kardeslerim.webp',
          imdb: '6.9',
          imdbLink: 'https://www.imdb.com/title/tt13550498/',
        },
        {
          title: 'Kaderimin Oyunu',
          src: '../../../assets/casting/2021/ko.webp',
          imdb: '6.1',
          imdbLink: 'https://www.imdb.com/title/tt15471722/',
        },
        {
          title: 'Mahkum',
          src: '../../../assets/casting/2021/mahkum.webp',
          imdb: '6.1',
          imdbLink: 'https://www.imdb.com/title/tt14898744/',
        },
        {
          title: 'Annemizi Saklarken',
          src: '../../../assets/casting/2021/annemizi saklarken2021.jpg',
          imdb: '7.4',
          imdbLink: 'https://www.imdb.com/title/tt15793068/',
        },
      ],
    },
    {
      year: '2020',
      movies: [
        {
          title: 'Arıza',
          src: '../../../assets/casting/2020/ariza2020.jpg',
          type: 'dizi',
          imdb: '6.4',
          imdbLink: 'https://www.imdb.com/title/tt12735946/',
        },
        {
          title: 'Baba Parası',
          src: '../../../assets/casting/2020/babaparasi2020.jpg',
          type: 'dizi',
          imdb: '4.5',
          imdbLink: 'https://www.imdb.com/title/tt10549312/',
        },
        {
          title: 'İyi Günde Kötü Günde',
          src: '../../../assets/casting/2020/iyigundekotugunde2020.jpg',
          imdb: '6.4',
          imdbLink: 'https://www.imdb.com/title/tt12872494/',
        }
      ],
    },
    {
      year: '2019',
      movies: [
        {
          title: 'Sefirin Kızı',
          src: '../../../assets/casting/2019/sefirinkizi2019.webp',
          type: 'dizi',
          imdb: '5.9',
          imdbLink: 'https://www.imdb.com/title/tt11256614/',
        },
        {
          title: 'Zalim İstanbul',
          src: '../../../assets/casting/2019/zalimistanbul.jpeg',
          type: 'dizi',
          imdb: '5.8',
          imdbLink: 'https://www.imdb.com/title/tt9910728/',
        },
      ],
    },
    {
      year: '2018',
      movies: [
        {
          title: 'İyi Oyun',
          src: '../../../assets/casting/2018/iyioyun2018.jpg',
          type: 'film',
          imdb: '4.8',
          imdbLink: 'https://www.imdb.com/title/tt8510324/',
        },
        {
          title: 'Ölümlü Dünya',
          src: '../../../assets/casting/2018/olumludunya2018.jpg',
          type: 'film',
          imdb: '7.7',
          imdbLink: 'https://www.imdb.com/title/tt7748244/',
        },
      ],
    },
    {
      year: '2017',
      movies: [
        {
          title: '7YUZ',
          src: '../../../assets/casting/2017/7yuz2017.jpg',
          type: 'dizi',
          imdb: '7.7',
          imdbLink: 'https://www.imdb.com/title/tt7404870/',
        },
        {
          title: 'Adı Efsane',
          src: '../../../assets/casting/2017/adiefsane2017.jpg',
          type: 'dizi',
          imdb: '6.5',
          imdbLink: 'https://www.imdb.com/title/tt6550350/',
        },
        {
          title: 'Masum',
          src: '../../../assets/casting/2017/masum2017.jpg',
          type: 'dizi',
          imdb: '8.4',
          imdbLink: 'https://www.imdb.com/title/tt6478318/',
        },
        {
          title: 'Sahipli',
          src: '../../../assets/casting/2017/sahipli2017.jpg',
          type: 'dizi',
          imdb: '7.9',
          imdbLink: 'https://www.imdb.com/title/tt6573358/',
        },
        {
          title: 'Siyah Beyaz Aşk',
          src: '../../../assets/casting/2017/siyahbeyaz.jpeg',
          type: 'dizi',
          imdb: '8.1',
          imdbLink: 'https://www.imdb.com/title/tt7550774/',
        }
      ],
    },
    {
      year: '2016',
      movies: [
        {
          title: 'Hayat Şarkısı',
          src: '../../../assets/casting/2016/hayatsarkisi2016.jpg',
          type: 'dizi',
          imdb: '7.7',
          imdbLink: 'https://www.imdb.com/title/tt5302120/',
        },
        {
          title: 'İkimizin Yerine',
          src: '../../../assets/casting/2016/ikimiznyerine2016.jpeg',
          type: 'dizi',
          imdb: '6.1',
          imdbLink: 'https://www.imdb.com/title/tt5934922/',
        },
        {
          title: 'Kış Güneşi',
          src: '../../../assets/casting/2016/kisgunesi2016.jpg',
          type: 'dizi',
          imdb: '6.7',
          imdbLink: 'https://www.imdb.com/title/tt7180934/',
        },
        {
          title: 'Mahrumlar',
          src: '../../../assets/casting/2016/mahrumlar2016.jpeg',
          type: 'dizi',
          imdb: '5.0',
          imdbLink: 'https://www.imdb.com/title/tt9092718/',
        },
        {
          title: 'Tatlı İntikam',
          src: '../../../assets/casting/2016/tatliintikam2016.jpg',
          type: 'dizi',
          imdb: '5.9',
          imdbLink: 'https://www.imdb.com/title/tt5583950/',
        }
      ],
    },
    {
      year: '2015',
      movies: [
        {
          title: 'Bana Masal Anlatma',
          src: '../../../assets/casting/2015/banamasalanlat2015.jpeg',
          type: 'film',
          imdb: '7.4',
          imdbLink: 'https://www.imdb.com/title/tt4195278/',
        },
        {
          title: 'Hatırla Gönül',
          src: '../../../assets/casting/2015/hatirlagonul2015.jpg',
          type: 'dizi',
          imdb: '7.0',
          imdbLink: 'https://www.imdb.com/title/tt5151004/',
        },
        {
          title: 'Kara Bela',
          src: '../../../assets/casting/2015/karabela2015.jpg',
          type: 'dizi',
          imdb: '6.8',
          imdbLink: 'https://www.imdb.com/title/tt4974684/',
        },
        {
          title: 'Takım: Mahalle Aşkına',
          src: '../../../assets/casting/2015/mahalleaskina2015.jpg',
          type: 'dizi',
          imdb: '5.9',
          imdbLink: 'https://www.imdb.com/title/tt5074648/',
        },
        {
          title: 'Sevdam Alabora',
          src: '../../../assets/casting/2015/sevdamalabora2015.jpg',
          type: 'dizi',
          imdb: '6.5',
          imdbLink: 'https://www.imdb.com/title/tt7766148/',
        },
        {
          title: "Yaz'ın Öyküsü",
          src: '../../../assets/casting/2015/yazinoykusu.jpg',
          type: 'dizi',
          imdb: '4.5',
          imdbLink: 'https://www.imdb.com/title/tt4850010/',
        }
      ],
    },
    {
      year: '2014',
      movies: [
        {
          title: 'Hayat Yolunda',
          src: '../../../assets/casting/2014/hayatyolunda.jpg',
          type: 'dizi',
          imdb: '5.7',
          imdbLink: 'https://www.imdb.com/title/tt6369486/',
        },
        {
          title: 'Ulan İstanbul',
          src: '../../../assets/casting/2014/ulanistanbul.jpg',
          type: 'dizi',
          imdb: '7.1',
          imdbLink: 'https://www.imdb.com/title/tt4824056/',
        }
      ],
    },
    {
      year: '2012',
      movies: [
        {
          title: 'Kanıt',
          src: '../../../assets/casting/2012/kanit.jpg',
          type: 'dizi',
          imdb: '7.5',
          imdbLink: 'https://www.imdb.com/title/tt2151208/',
        },
        {
          title: 'Patlak Sokaklar Gerzomat',
          src: '../../../assets/casting/2012/patlaksokaklar.jpeg',
          type: 'film',
          imdb: '7.5',
          imdbLink: 'https://www.imdb.com/title/tt2243471/',
        },
      ],
    },
  ];

  constructor() {}

  ngOnInit(): void {}
}
