<div class="d-flex flex-column justify-content-center align-items-center row">
  <div class="col-md-8 col-sm-12">
    <!-- <h3>HAKKIMIZDA</h3> -->
    <p class="subhead">
      Sema Hatun Bilmiş, Marmara Üniversitesi İletişim Fakültesi Radyo, Sinema
      ve Televizyon bölümünde eğitimini tamamladı. Okul yıllarının başlarında
      sektörün önde gelen isimleri ile çalışma fırsatı yakaladı. 2010-2013
      yılları arasında ANS Yapım çatısı altında yer alan Acans Entertainment
      bünyesinde cast direktörlüğü ve menajerlik departmanında çalışmaya
      başladı. L.A Santa Monica College’da casting ve sinema eğitimi aldı.
    </p>
    <p >
      Eğitiminin ardından Türkiye’ye dönerek televizyon endüstrisinin
      öncülerinden Kanal D iç yapım şirketi olan D Productions’da 4 yıl boyunca
      in house cast sorumlusu olarak çalıştı. 2016 yılında kendi şirketi Sem’s
      Casting and Management’ı kurdu.
    </p>
  </div>
  <img src="../../../assets/icons/cast logo.png" class="bracket" alt="">
</div>

