<div class="d-flex flex-column justify-content-center align-items-center row">
  <div class="col-md-8">
    <div class="list">
      <div class="news-box my-3">
        <div class="news-text">
          <h2>Bir Küçük Gün Işığı</h2>
          <h4>
            "Bir kadın bir adamı ne kadar sevebilir?"
          </h4>
          <div class="news-image">
            <img
              src="../../../../assets/casting/2022/gunisigi.webp"
              alt=""
            />
          </div>
          <p>
            Senaryosunu Özgür Evren Heptürk'ün kaleme aldığı yönetmen koltuğunda ise Emre Kabakuşak'ın oturduğu dizi, tüm ezberleri bozarak her şeye yeniden başlamak zorunda kalan Elif'in karanlıktan gün ışığına çıkma hikayesini konu alıyor. 10 yıldır bir yalanın içinde yaşayan Elif'in hayatı bir haberle alt üst olur. Çaresiz genç kadının yolu zengin iş adamı Fırat ile beklenmedik bir şekilde kesiştiğinde ise her şey daha da karışır. Yürekleri ısıtan anlatımı ve kalplere dokunan hikayesi ile "Bir Küçük Gün Işığı" Eylül'de atv'de.
          </p>
          <p>
            Bilemezsin, belki de en büyük hediyedir dayanamayacağını sandığın acılar…
          </p>
          <a
            href="https://www.atv.com.tr/bir-kucuk-gun-isigi/hakkinda"
            target="_blank"
            >Kaynak</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
