import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import {
  doc,
  addDoc,
  updateDoc,
  collection,
  getFirestore,
} from 'firebase/firestore';
import { Contact, IContact } from 'src/app/core/models/contact.model';
import { getAuth, signInAnonymously } from 'firebase/auth';

const db = getFirestore();
const auth = getAuth();

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  contact: IContact = new Contact();
  date: Date = new Date();
  isSent: boolean = false;
  constructor() {
    signInAnonymously(auth)
      .then(() => {
        // Signed in..
        console.log('anonymously logged in');
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ...
      });
  }
  ngOnInit(): void {}

  async submit() {
    console.log('Form: ', this.contact);
    try {
      const docRef = await addDoc(collection(db, 'contacts'), {
        isim: this.contact.isim,
        telefon: this.contact.telefon,
        email: this.contact.email,
        konu: this.contact.konu,
        mesaj: this.contact.mesaj,
        tarih: this.date,
      });
      console.log('Document written with ID: ', docRef.id);
      const docRef2 = doc(db, 'contacts', docRef.id);
      await updateDoc(docRef2, {
        id: docRef.id,
      });
      this.isSent = true;
    } catch (e) {
      console.error('Error adding document: ', e);
      this.isSent = false;
    }
  }
}
