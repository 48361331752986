<div class="d-flex flex-column justify-content-center align-items-center row">
  <div class="col-md-8">
    <div class="list">
      <div class="news-box my-3">
        <div class="news-text">
          <h2>Arıza</h2>
          <h4>Arıza'nın afişi yayınlandı!</h4>
          <div class="news-image">
            <img
              src="https://im.showtv.com.tr/2020/09/04/ver1600949809/2793826_1920x1080.jpg"
              alt=""
            />
          </div>
          <p>
            Show TV’nin yeni sezon dizisi, O3 Medya imzasını taşıyan,
            başrollerini Tolga Sarıtaş ve Ayça Ayşin Turan’ın paylaştığı
            'Arıza'Pazar akşamı izleyici ile buluşuyor. Ekran için gün sayan
            dizinin afişi de yayınlandı.
          </p>
          <p>
            Afişte yer alan duruşlarıyla göz dolduran ikili tutkulu ama bir o
            kadar imkansız bir aşkın kahramanı olacaklar.
          </p>
          <p>
            Tolga Sarıtaş, ailesine düşkün, hayatını sevdiklerine adamış dürüst
            bir adam olan Ali Rıza karakteriyle, Ayça Ayşin Turan ise babasının
            karanlık dünyasından uzak durmaya çalışan başarılı doktor Halide
            olarak izleyici karşısına çıkacak.
          </p>
          <p>
            Beğeni toplayan afişi ile heyecan yaratan “Arıza” nefes kesen
            hikayesi, sürükleyici temposu ve güçlü isimleri ile Pazar akşamı
            Show TV’de başlıyor!
          </p>
          <a
            href="https://www.showtv.com.tr/dizi/haber/2793826-show-tv-nin-iddiali-dizisi-ariza-nin-afisi-yayinlandi"
            target="_blank"
            >Kaynak</a
          >
        </div>
      </div>
    </div>
  </div>
</div>

