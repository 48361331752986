<div class="main">
  <div class="col-md-8">
    <iframe
      src="https://tally.so/embed/nrgQom?hideTitle=1&transparentBackground=1&dynamicHeight=1"
      loading="lazy"
      width="100%"
      height="601"
      frameborder="0"
      marginheight="0"
      marginwidth="0"
      title="Contact form
    "
    ></iframe>
    <script>
      var d = document,
        w = "https://tally.so/widgets/embed.js",
        v = function () {
          "undefined" != typeof Tally
            ? Tally.loadEmbeds()
            : d
                .querySelectorAll("iframe[data-tally-src]:not([src])")
                .forEach(function (e) {
                  e.src = e.dataset.tallySrc;
                });
        };
      if ("undefined" != typeof Tally) v();
      else if (d.querySelector('script[src="' + w + '"]') == null) {
        var s = d.createElement("script");
        (s.src = w), (s.onload = v), (s.onerror = v), d.body.appendChild(s);
      }
    </script>
    <!-- <div *ngIf="!isSent">
      <form  #contactForm="ngForm">
        <div class="form-row">
          <div class="form-group">
            <label for="isim">Ad Soyad *</label>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="contact.isim"
              name="isim"
              #isim="ngModel"
              required
            />
            <span
              class="text-danger my-1"
              *ngIf="(isim.touched || contactForm.submitted) && isim.errors?.required"
            >
              İsim boş bırakılamaz.
            </span>
          </div>
        </div>
        <div class="form-row my-3">
          <div class="form-group">
            <label for="telefon">Telefon *</label>
            <input
              type="text"
              [(ngModel)]="contact.telefon"
              class="form-control"
              name="telefon"
              #telefon="ngModel"
              required
            />
            <span
              class="text-danger my-1"
              *ngIf="(telefon.touched || contactForm.submitted) && telefon.errors?.required"
            >
              Telefon numarası boş bırakılamaz.
            </span>
          </div>
          <div class="form-group">
            <label for="email">Mail *</label>
            <input
              type="text"
              [(ngModel)]="contact.email"
              class="form-control"
              name="email"
              #email="ngModel"
              required
            />
            <span
              class="text-danger my-1"
              *ngIf="(email.touched || contactForm.submitted) && email.errors?.required"
            >
              Email adres boş bırakılamaz.
            </span>
          </div>
        </div>
        <div class="form-row my-3">
          <div class="form-group">
            <label for="konu">Konu *</label>
            <input
              [(ngModel)]="contact.konu"
              type="text"
              class="form-control"
              name="konu"
              #konu="ngModel"
              required
            />
            <span
              class="text-danger my-1"
              *ngIf="(konu.touched || contactForm.submitted) && konu.errors?.required"
            >
              Konu alanı boş bırakılamaz.
            </span>
          </div>
        </div>
        <div class="form-row my-3">
          <div class="form-group">
            <label for="mesaj">Mesajınız *</label>
            <textarea
              type="text"
              class="form-control"
              [(ngModel)]="contact.mesaj"
              rows="3"
              name="mesaj"
              #mesaj="ngModel"
              required
            ></textarea>
            <span
              class="text-danger my-1"
              *ngIf="(mesaj.touched || contactForm.submitted) && mesaj.errors?.required"
            >
              Mesaj alanı boş bırakılamaz.
            </span>
          </div>
        </div>
        <button type="submit" class="btn submit-button my-3" (click)="submit()">
          Formu Gönder
        </button>
      </form>
    <div class="custom-mail">
      <p>ya da</p>
      <p>Bize mail ile ulaşabilirsiniz.</p>
      <a href="mailto:info@semscasting.com" target="_blank">info@semscasting.com</a>
    </div>
    </div>
    <div *ngIf="isSent">
      <div
        class="d-flex flex-row justify-content-center align-items-center text-center my-5"
      >
        <h3>Formunuz başarıyla bize ulaştı. En kısa sürede görüşmek üzere.</h3>
      </div>
    </div> -->
  </div>
</div>
