import { Component, HostListener, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger(
      'inOutAnimation', 
      [
        transition(
          ':enter', 
          [
            style({ height: 0, opacity: 0 }),
            animate('1s ease-out', 
                    style({ height: 800, opacity: 1 }))
          ]
        ),
        transition(
          ':leave', 
          [
            style({ height: 800, opacity: 1 }),
            animate('1s ease-in', 
                    style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class HeaderComponent implements OnInit {
  pageWidth = window.innerWidth;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.pageWidth = event.target.innerWidth;
  }
  isNavOpen: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

  openNav() {
    console.log('navbar');
    this.isNavOpen = true;
  }
  closeNav() {
    this.isNavOpen = false
  }
}
