<!-- <iframe
  src="https://rgb-kinetic-slider.vercel.app/index2.html"
  frameborder="0"
  loading="eager"
  *ngIf="pageWidth > 768; px"
></iframe>

<iframe
  src="https://rgb-kinetic-slider.vercel.app/index4.html"
  frameborder="0"
  loading="eager"
  *ngIf="pageWidth <= 768; px"
></iframe> -->

<video
  loop
  muted
  autoplay
  playsinline
  oncanplay="this.play()"
  onloadedmetadata="this.muted = true"
  *ngIf="pageWidth > 768px"
  src="../../../assets/video/web-c.mp4"
></video>

<video
  *ngIf="pageWidth <= 768px"
  loop
  muted
  autoplay
  playsinline
  oncanplay="this.play()"
  onloadedmetadata="this.muted = true"
  src="../../../assets/video/mobile-c.mp4"
></video>