<div class="d-flex flex-column justify-content-center align-items-center row">
  <div class="col-md-8">
    <div class="list">
      <div class="news-box my-3">
        <div class="news-text">
          <h2>Kardeşlerim</h2>
          <h4>
            atv'nin yeni dizisi Kardeşlerim'in merakla beklenen afişi yayınlandı
          </h4>
          <p>
            Yapımına NGM'in imza attığı yönetmenliğini Serkan Birinci'nin
            üstlendiği, senaryosunu Gül Abus Semerci'nin kaleme aldığı atv'nin
            ilgi uyandıran dizisi "Kardeşlerim" in afişi yayınlandı.
          </p>
          <p>
            Hiç beklemedikleri bir anda hem anne hem de babasız kalan 4 kardeşin
            kalplere dokunan sıcacık hikayesi afişe de yansıdı. Tanıtımıyla
            dikkat çeken Kardeşlerim'in afişi de sosyal medyada çok beğenildi.
            İzleyicinin merakla beklediği, Celil Nalçakan, Halit Özgür Sarı, Ahu
            Yağtu, Fadik Sevin Atasoy, Cüneyt Mete, Su Burcu Yazgı Coşkun, Yiğit
            Koçak, Simge Selçuk, Damla Su İkizoğlu, Onur Seyit Yaran, Cihan
            Şimşek, Kaan Sevi gibi güçlü oyuncularla genç yıldızları kadrosunda
            buluşturan "Kardeşlerim" 20 Şubat Cumartesi akşamı atv'de başlıyor.
          </p>
          <a
            href="https://www.atv.com.tr/haberler/2021/02/12/atvnin-yeni-dizisi-kardeslerimin-merakla-beklenen-afisi-yayinlandi"
            target="_blank"
            >Kaynak</a
          >
        </div>
        <div class="news-image">
          <img
            src="https://iaatv.tmgrup.com.tr/a66da6/0/0/0/0/1197/2048?u=https://iatv.tmgrup.com.tr/2021/02/12/atvnin-yeni-dizisi-kardeslerimin-merakla-beklenen-afisi-yayinlandi-1613123428571.jpg&mw=600"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</div>
