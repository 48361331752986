import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bir-kucuk-gun-isigi',
  templateUrl: './bir-kucuk-gun-isigi.component.html',
  styleUrls: ['./bir-kucuk-gun-isigi.component.scss']
})
export class BirKucukGunIsigiComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
