<div class="d-flex flex-column justify-content-center align-items-center row">
  <div class="col-md-8">
    <div class="list">
      <div class="news-box my-3">
        <div class="news-text">
          <h2>Hayaller ve Hayatlar</h2>
          <h4>Hayaller ve Hayatlar yayında!
          </h4>
          <p>Hayallerinin peşinde koşarken hayata yakalananların hikayesini anlatan. beIN Originals etiketli yeni dizi “Hayaller ve Hayatlar” 17 Şubatta sizlerle…</p>
          <a
            href="https://www.beinconnect.com.tr/bein-originals/hayaller-ve-hayatlar"
            target="_blank"
            >Kaynak</a
          >
        </div>
        <div class="news-image">
          <img
            src="../../../../assets/casting/2022/hvh.webp"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</div>
