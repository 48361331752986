import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { AboutComponent } from './pages/about/about.component';
import { BookComponent } from './pages/book/book.component';
import { JoinComponent } from './pages/join/join.component';
import { ContactComponent } from './pages/contact/contact.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { CastingComponent } from './pages/casting/casting.component';
import { ManagementComponent } from './pages/management/management.component';
import { NewsComponent } from './pages/news/news.component';
import { environment } from 'src/environments/environment';
import { SigninComponent } from './auth/signin/signin.component';
import { SignupComponent } from './auth/signup/signup.component';
import { ProfileComponent } from './auth/profile/profile.component';
import { HayallerHayatlarComponent } from './pages/news/hayaller-hayatlar/hayaller-hayatlar.component';
import { MahkumComponent } from './pages/news/mahkum/mahkum.component';
import { KaderiminOyunuComponent } from './pages/news/kaderimin-oyunu/kaderimin-oyunu.component';
import { EvlilikHakkindaHerSeyComponent } from './pages/news/evlilik-hakkinda-her-sey/evlilik-hakkinda-her-sey.component';
import { KardeslerimComponent } from './pages/news/kardeslerim/kardeslerim.component';
import { ArizaComponent } from './pages/news/ariza/ariza.component';
import { SefirinKiziComponent } from './pages/news/sefirin-kizi/sefirin-kizi.component';
import { ResetComponent } from './auth/reset/reset.component';
import { PagenotfoundComponent } from './pages/pagenotfound/pagenotfound.component';
import { TacsizPrensesComponent } from './pages/news/tacsiz-prenses/tacsiz-prenses.component';
import { KusursuzKiraciComponent } from './pages/news/kusursuz-kiraci/kusursuz-kiraci.component';
import { BirKucukGunIsigiComponent } from './pages/news/bir-kucuk-gun-isigi/bir-kucuk-gun-isigi.component';
import { DunyaylaBenimAramdaComponent } from './pages/news/dunyayla-benim-aramda/dunyayla-benim-aramda.component';

const app = initializeApp(environment.firebaseConfig);
const db = getFirestore();
const analytics = getAnalytics(app);


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    BookComponent,
    JoinComponent,
    ContactComponent,
    HeaderComponent,
    FooterComponent,
    CastingComponent,
    ManagementComponent,
    NewsComponent,
    SigninComponent,
    SignupComponent,
    ProfileComponent,
    HayallerHayatlarComponent,
    MahkumComponent,
    KaderiminOyunuComponent,
    EvlilikHakkindaHerSeyComponent,
    KardeslerimComponent,
    ArizaComponent,
    SefirinKiziComponent,
    ResetComponent,
    PagenotfoundComponent,
    TacsizPrensesComponent,
    KusursuzKiraciComponent,
    BirKucukGunIsigiComponent,
    DunyaylaBenimAramdaComponent,
  ],
  imports: [BrowserModule, BrowserAnimationsModule, AppRoutingModule, FormsModule],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
