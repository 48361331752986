import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dunyayla-benim-aramda',
  templateUrl: './dunyayla-benim-aramda.component.html',
  styleUrls: ['./dunyayla-benim-aramda.component.scss']
})
export class DunyaylaBenimAramdaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
