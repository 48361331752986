import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-management',
  templateUrl: './management.component.html',
  styleUrls: ['./management.component.scss']
})
export class ManagementComponent implements OnInit {
  cardList = [
    {
      name: 'Ad Soyad',
      src: '../../../assets/images/placeholder.webp',
      ig: '',
      tw: '',
      tik: '',
    },
    {
      name: 'Ad Soyad',
      src: '../../../assets/images/placeholder.webp',
      ig: '',
      tw: '',
      tik: '',
    },
    {
      name: 'Ad Soyad',
      src: '../../../assets/images/placeholder.webp',
      ig: '',
      tw: '',
      tik: '',
    },
    {
      name: 'Ad Soyad',
      src: '../../../assets/images/placeholder.webp',
      ig: '',
      tw: '',
      tik: '',
    },
    {
      name: 'Ad Soyad',
      src: '../../../assets/images/placeholder.webp',
      ig: '',
      tw: '',
      tik: '',
    },
    {
      name: 'Ad Soyad',
      src: '../../../assets/images/placeholder.webp',
      ig: '',
      tw: '',
      tik: '',
    },
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
